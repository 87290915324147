import { AccountType } from '../AccountType.types';
import {
  ClearingAccount,
  FeeAccount,
  PayoutAccount,
  ReferenceAccount,
} from '../AuxiliaryAccount.types';
import { DateIsoString, DateString } from '../Date.types';
import { IPS } from '../IPS';
import { SubscriptionPackage } from '../Subscription';
import { WphgDetailsExistingCustomer, WphgHistory } from '../Wphg';

import { User } from './User.types';

export type BloxxterAsset = {
  conditionId: string;
  numberOfTokens: number;
  valueOfTokens: number;
  numberOfTokensPending: number;
  valueOfTokensPending: number;
  numberOfTokensNotPaid: number;
  valueOfTokensNotPaid: number;
};

export enum BusinessPartnerStatus {
  Open = 'OPEN',
  Inclose = 'INCLOSE',
  Pending = 'PENDING',
}

export type Provider = 'QUIRION' | 'BLOXXTER' | undefined;

export type SourceOfFunds = {
  agricultureAndForestry: boolean;
  businessOperations: boolean;
  capitalAssets: boolean;
  donation: boolean;
  independentWork: boolean;
  inheritance: boolean;
  nonindependentWork: boolean;
  rentingAndLeasing: boolean;

  // the following were added later, BPs / Case without these may exists
  disposalOfOwnTrade?: boolean;
  insuranceBenefit?: boolean;
  saleOfOwnProperty?: boolean;
};

export type ValueHistory =
  | undefined
  | {
      d: DateString;
      v: number;
    }[];

/**
 * Every user has at least one BusinessPartner. They are basically accounts.
 * Multiple are possible, for example a user can have a BusinessPartner for himself
 * and one for his child / spouse.
 *
 * Holds general account data, as well as information about what is included (@see IPS),
 * [referenced only by ID].
 *
 */
export type BusinessPartner = {
  primaryKey?: string;
  sortKey?: string;

  businessPartnerId: number;
  customName?: string; // doesn't exist yet, but is planned
  name: string;
  status: BusinessPartnerStatus;
  accountType: AccountType;
  username: string;
  provider: Provider;
  createdAt: DateIsoString;
  updatedAt: DateIsoString;

  balance: number;
  balanceDate: DateIsoString;
  performanceStartDate: DateIsoString;
  mwrPerformanceStart: number;
  twrPerformanceYearStart: number;
  twrPerformanceStart: number;

  clearing?: ClearingAccount;
  reference?: ReferenceAccount;
  fee?: FeeAccount;
  payout?: PayoutAccount;

  sourceOfFunds?: SourceOfFunds;
  openedAt?: DateIsoString;
  role?: string;
  products?: IPS[] | number[];
  /**
   * @deprecated use `products` instead
   */
  ips?: number[];
  history?: ValueHistory;
  historyYear?: ValueHistory;
  users?: User[];
  wphg?: WphgDetailsExistingCustomer;
  wphgExpiresAt?: DateIsoString;
  wphgHistory?: WphgHistory; // deprecated
  bloxxterPortfolio?: BloxxterAsset[];
  /**
   * The package the user is currently subscribed to.
   */
  subscriptionType?: SubscriptionPackage;
  /**
   * Date of switch to this package. Format: YYYY-MM-DD
   */
  subscriptionSwitchDate?: DateString;
  meta?: {
    bonusClearingAccountPlusInterestRate?: number;
    clearingAccountPlusInterestRate?: number;
  };
};

export type BusinessPartnerRecord = Record<
  BusinessPartner['businessPartnerId'],
  BusinessPartner
>;
